.ws{
    max-height: 100vh;
    overflow-wrap: wrap;
    height: 98vh;
    width: 21vw;
    /* background-color: #1363c6 ; */
    background-image: url('https://storage.googleapis.com/kreatewebsites/sites/site103/img/bg-hero.png');
    border-radius: 15px;
    text-align: center;
    display: flex;
    flex-direction: column; /* Arrange child elements vertically */
    justify-content: space-between; /* Push elements to the top and bottom */
    align-items: center; /* Center child elements horizontally */
    box-shadow: 0 3px 4px rgba(0, 0, 0, 4);
}

.sidebar{
   width: 100%;   
   display: flex;
   flex-direction: column;
   text-align: center;
   height: 100%;
}

.home{
    margin-left: 2.5vw;
}

.head{
    color: rgba(243, 220, 47, 0.823);
    font-family: "Arial Black", Gadget, sans-serif;
    font-weight: bolder;
    font-size: 2rem;
    text-shadow: 0 5px 4px rgba(0, 0, 0, 0.5);
}
.head2{
  color: rgba(181, 181, 181, 0.823);
  font-family: "Arial Black", Gadget, sans-serif;
  font-weight: bolder;
  font-size: 15px;
  text-shadow: 0 5px 4px rgba(0, 0, 0, 0.5);
}

.top{
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.strip{
    box-shadow: 0 3px 10px rgba(0, 0, 0, 9);
    width: 100%;
    margin-top: 3vh;
}

.icon{
    font-size: 3vh;
    margin-left:1vw;
}

.icon:hover{
    transform: scale(1.1);
}

.options{
    margin-left: 4vw;
    margin-right: 3vw;
    margin-top: 2vh;
    width: max-content;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.dot{
    color: rgb(222, 222, 222);
}

.hero {
    width: 98%; 
    height: auto;
    opacity: 0.5;
    align-self: end;
  }
  
  
.signup{
    color: whitesmoke;
    font-size: small;
    font-weight: 400;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    animation: slideInRight 0.2s ease-in-out !important;
    
  }

  .signin:hover{
    transform: scale(1.08);
    cursor: pointer;
  }
  @keyframes slideInRight {
    0% {
      transform: translateX(0.01%); 
      opacity: 0;
    }
    100% {
      transform: translateX(0); 
      opacity: 1;
    }
  }
  
  @media (max-width: 600px)
{

  .ws{
    width: 17vw;
    height: 90vh;
  }
  .head{
    display: none;
  }
  .hero{
    display: none;
  }
  .options{
    margin-left: 5vw;
    margin-right: 3vw;
    margin-top: 2vh;
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.signup{
  display: none;
}
#setting{
  display: none;
}
  

}
