.oicon{
    /* width: 2vw;
    height: 4vh; */
    width: 40px;
    height: 30px;
   
}
.r-icon{
    position: relative;
    margin-left: 3.3vw;
    margin-top: -0.4vh;
    margin-bottom: 2vh;
    display: flex;
}
.f-icon{
    padding: 5px;
    font-size: 1.8rem;
}
.s-icon{
    padding: 5px;
    font-size: 1.8rem;
}
.t-icon{
    padding: 5px;
    font-size: 1.8rem;
}
.hoverfIcon, .hoversIcon, .hovertIcon{
    display: none;
    width: max-content;
    font-size: 0.6rem;
}
.f-icon:hover{
    border-radius: 20px;
   background-color: rgba(146, 146, 146, 0.304);
  

}
.f-icon:hover + .hoverfIcon {
    display: block;
}
.s-icon:hover{
    border-radius: 20px;
    background-color: rgba(146, 146, 146, 0.304);
}
.s-icon:hover + .hoversIcon{
    display: block;
}
.t-icon:hover{
    border-radius: 20px;
    background-color: rgba(146, 146, 146, 0.304);
}
.t-icon:hover + .hovertIcon{
    display: block;
}

.dropdown{
    background-color: rgba(221, 221, 221, 0.933);
    color: rgb(11, 11, 11);
    position: absolute;
    font-size: 0.6rem;
    border-radius: 5px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    width: max-content;
    top: -12.5vh;    
}
.doptions{
    padding: 8px;
}
.doptions:hover{
background-color: rgba(85, 85, 85, 0.225);
}

label {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
}

.toggle {
    opacity: 0;
    width: 0;
    height: 0;
}

.sp {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2c3e50;
    transition: 0.3s;
    border-radius: 30px;
}
.sp:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    left: 3px;
    bottom: 2.6px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
  }
  
  .toggle:checked + .sp {
    background-color: #00c853;
  }
  
  .toggle:checked + .sp:before {
    transform: translateX(29px);
  }
  
  strong {
    position: absolute;
    left: 100%;
    width: max-content;
    line-height: 30px;
    margin-left: 10px;
    cursor: pointer;
}